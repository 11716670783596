






















































































import Vue from "vue";
import AuthService from "@/services/AuthService";
import firebase from "firebase/app";
import "firebase/auth";
import { apiAxios } from "@/store/apiAxios";
import ModalContainer from "../ModalContainer.vue";
import i18n from "@/i18n";

export default Vue.extend({
  name: "LoginView",
  components: { ModalContainer },
  data() {
    return {
      email: "",
      password: null,
      error: "",
      phoneNumber: "",
      confirmation: "",
      otpVisible: false,
      otp: "",
      messageReset: false,
      resetComplete: true,
    };
  },
  mounted() {
    if (this.$route.params.pass_reset == "process") {
      this.messageReset = true;
      this.$bvModal.show("modal-reset");
    } else if (this.$route.params.pass_reset == "reset") {
      this.resetComplete = true;
      this.$bvModal.show("modal-reset-completed");
    }
  },
  methods: {
    async login(): Promise<void> {
      const payload = {
        email: this.email,
        password: this.password,
      };
      const emailActive = {
        email: this.email,
      };
      await AuthService.tfaDetails(payload)
        .then((res) => {
          if (res?.data == "email") {
            this.error = "";
            AuthService.getActiveUserStatus(emailActive).then((response) => {
              if (response.data == 1) {
                AuthService.loginEmail(payload).then((r) => {
                  if (r?.data.status == 204) {
                    AuthService.returnPayload(payload).then((response) => {
                      this.$router.push({
                        name: "Two Factor",
                        params: {
                          email: response.email,
                          password: response.password,
                          t: "email",
                        },
                      });
                      return;
                    });
                  } else {
                    this.error = i18n.t("auth.error-username-password");
                  }
                });
              } else {
                AuthService.logout();
                this.error = i18n.t("auth.error-not-active");
              }
            });
          } else if (res?.data == "app") {
            this.error = "";
            AuthService.getActiveUserStatus(emailActive).then((response) => {
              if (response.data == 1) {
                AuthService.login(payload).then((r) => {
                  if (r?.data?.two_factor == true) {
                    this.$router.push({
                      name: "Two Factor",
                      params: {
                        t: "app",
                        email: this.email,
                      },
                    });
                    return;
                  }
                });
              } else {
                AuthService.logout();
                this.error = i18n.t("auth.error-not-active");
              }
            });
          } else if (res?.data == "sms") {
            this.error = "";
            AuthService.getActiveUserStatus(emailActive).then((response) => {
              if (response.data == 1) {
                AuthService.returnUserPhoneNumber(payload).then((r) => {
                  if (r.data.status == 204) {
                    this.phoneNumber = r.data.phone;
                    AuthService.logout();
                    this.phoneSignIn();
                  } else {
                    this.error = i18n.t("auth.error-username-password");
                  }
                });
              } else {
                AuthService.logout();
                this.error = i18n.t("auth.error-not-active");
              }
            });
          }
        })
        .catch(() => {
          this.error = i18n.t("auth.error-username-password");
          return;
        });
    },
    async getUser() {
      this.error = "";
      const authUser = await this.$store.dispatch("auth/getAuthUser");
      if (authUser) {
        if (
          process.env.VUE_APP_SHOW_ADMIN_PANEL === "true" &&
          authUser.role_id === 1
        ) {
          this.$router.push("/admin/dashboard").catch();
        } else {
          this.$router.push("/").catch();
        }
      } else {
        this.error =
          "Unable to fetch user after login, check your API settings.";
      }
    },

    phoneSignIn() {
      // [START auth_phone_signin]
      // const phoneNumber = getPhoneNumberFromUserInput();
      const phoneNumber = this.phoneNumber;
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          this.otpVisible = true;
          window.confirmationResult = confirmationResult;
        })
        .catch((error: any) => {
          // Error; SMS not sent
          // ...
          this.error = error;
        });

      // [END auth_phone_signin]
    },

    verifyCode() {
      var password = this.password;
      var email = this.email;
      const payload = {
        email: this.email,
        type: "login",
        step: "login, code verified",
      };
      window.verifyingCode = true;
      var code = this.otp;
      window.confirmationResult
        .confirm(code)
        .then(() => {
          // User signed in successfully.
          window.verifyingCode = false;
          window.confirmationResult = null;
          AuthService.addLog(payload).then(() => {
            apiAxios
              .post("/login", {
                email: email,
                password: password,
              })
              .then(() => {
                this.getUser();
              });
          });
        })
        .catch(function (error: any) {
          // User couldn't sign in (bad verification code?)
          window.alert(error.message);
          window.verifyingCode = false;
        });
    },
  },
});
